export default {
  /**
   * @param {String} token
   * @param {Array<Number>} farmerIds
   * @param {String} date
   * @param {Object} params
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  list(token, farmerIds, date, params = {}, headers = {}, cancelToken = null) {
    return {
      url: `/staff/members/harvest/${date}`,
      method: 'GET',
      headers: {
        Authorization: token,
        ...headers,
      },
      params: {
        ...params,
        farmer_ids: farmerIds,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Array<Number>} farmerIds
   * @param {Object} params
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  calendar(token, farmerIds, params = {}, headers = {}, cancelToken = null) {
    return {
      url: '/staff/members/harvest',
      method: 'GET',
      headers: {
        Authorization: token,
        ...headers,
      },
      params: {
        ...params,
        farmer_ids: farmerIds,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },

}
