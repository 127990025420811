import axios, { headersTags } from '@/libs/axios'

import moment from '@/libs/moment'
import harvests from '@/apis/harvests'

/**
 @typedef CropHarvest
 @property {Number} id
 @property {string} color
 @property {string} emoji
 @property {string} name
 @property {Number} quantity
 @property {Number} total_weight
 @property {StaffMemberHarvest[]} harvest
 */

/**
 @typedef StaffMemberHarvest
 @property {Number} id
 @property {string} code
 @property {string} firstname
 @property {string} lastname
 @property {string} language
 @property {Number} quantity
 @property {Number} total_weight
 */

export default {
  namespaced: true,
  state: {
    /** @type {null|Array<CropHarvest>} list */
    cropHarvest: null,
    /** @type {string|null} lastPull */
    lastPull: null,
  },
  getters: {
    /**
     * @param state
     * @returns {boolean}
     */
    isFetch(state) {
      return state.cropHarvest !== null
        && state.lastPull !== null
    },
    /**
     * @param state
     * @returns {Array<CropHarvest>}
     */
    getCropHarvest(state) {
      return state.cropHarvest
    },
    /**
     * @param state
     * @returns {moment|null}
     */
    getLastPull(state) {
      return state.lastPull ? moment(state.lastPull) : null
    },
    /**
     * @param state
     * @returns {number}
     */
    count(state) {
      return state.cropHarvest !== null ? state.cropHarvest.length : 0
    },
    /**
     * @param state
     * @returns {boolean}
     */
    has(state) {
      return state.cropHarvest !== null && state.cropHarvest.filter(cp => cp.harvest.filter(st => st.quantity >= 1).length >= 1).length >= 1
    },
  },
  mutations: {
    async clear(state) {
      state.cropHarvest = null
      state.lastPull = null
    },
    /**
     * @param state
     * @param {Array<CropHarvest>} cropHarvest
     *
     * @returns {Promise<void>}
     */
    async set(state, cropHarvest) {
      state.cropHarvest = cropHarvest
      state.lastPull = moment()
        .locale('en')
        .format('YYYY-MM-DD HH:mm:ss')
    },
  },
  actions: {
    /**
     * @param commit
     * @param getters
     * @param rootGetters
     * @returns {Promise<boolean>}
     * @param {String} date
     * @param {Object} filters
     * @param {boolean} byPassStorageCaching
     */
    async fetch({
      commit,
      rootGetters,
    }, {
      filters = {},
      byPassStorageCaching = false,
    }) {
      /** @type {AxiosResponse<CropHarvest>} response */
      const response = await (axios(harvests.list(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getSelectedFarmers'].map(e => e.id),
        moment()
          .locale('en')
          .format('YYYY-MM-DD'),
        {
          ...filters,
        },
        {
          [headersTags.FIELDS]: JSON.stringify([
            'id',
            'emoji',
            'name',
            'color',
            'harvest',
            'quantity',
            'total_weight',
          ]),
          [headersTags.ORDER_BY]: JSON.stringify({
            name: 'asc',
          }),
        },
      )))

      if (byPassStorageCaching) {
        return response.data.data
      }

      commit('clear')
      commit('set', response.data.data)

      return true
    },
  },
}
