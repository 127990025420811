import { headersTags } from '@/libs/axios'

export default {
  /**
   * @param {String} token
   * @param {Array<Number>} farmerIds
   * @param {Object} params
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  list(token, farmerIds, params = {}, headers = {}, cancelToken = null) {
    return {
      url: '/campaigns',
      method: 'GET',
      headers: {
        Authorization: token,
        ...headers,
      },
      params: {
        farmer_ids: farmerIds,
        ...params,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Array<Number>} farmerIds
   * @param {Object} data
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  add(token, data = {}, headers = {}, cancelToken = null) {
    return {
      url: '/campaigns',
      method: 'POST',
      headers: {
        Authorization: token,
        ...headers,
      },
      data,
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Array<Number>} farmerIds
   * @param {Array<Number>} campaignId
   * @param {Object} data
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  edit(token, campaignId, data = {}, headers = {}, cancelToken = null) {
    console.log(data)
    return {
      url: `/campaigns/${campaignId}/`,
      method: 'PUT',
      headers: {
        Authorization: token,
        ...headers,
      },
      data,
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Array<Number>} farmerIds
   * @param {Array<Number>} campaignId
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  delete(token, campaignId, headers = {}, cancelToken = null) {
    return {
      url: `/campaigns/${campaignId}/`,
      method: 'DELETE',
      headers: {
        Authorization: token,
        ...headers,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Array<Number>} farmerIds
   * @param {Array<Number>} campaignId
   * @param {String} unit
   * @param {Object} data
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  units(token, farmerIds, campaignId, unit, data = {}, headers = {}, cancelToken = null) {
    return {
      url: `/campaigns/${campaignId}/harvest`,
      method: 'POST',
      headers: {
        Authorization: token,
        [headersTags.NO_PAGINATE]: true,
        ...headers,
      },

      data: {
        ...data,
        farmer_ids: farmerIds,
        unit,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Array<Number>} farmerIds
   * @param {Array<Number>} campaignId
   * @param {Object} data
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  stats(token, farmerIds, campaignId, data = {}, headers = {}, cancelToken = null) {
    return {
      url: `/campaigns/${campaignId}/harvest/stats`,
      method: 'POST',
      headers: {
        Authorization: token,
        ...headers,
      },
      data: {
        ...data,
        farmer_ids: farmerIds,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Array<Number>} farmerIds
   * @param {Array<Number>} campaignId
   * @param {String} unit
   * @param {Object} data
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  progression(token, farmerIds, campaignId, unit, data = {}, headers = {}, cancelToken = null) {
    return {
      url: `/campaigns/${campaignId}/harvest/progression`,
      method: 'POST',
      headers: {
        Authorization: token,
        ...headers,
      },
      data: {
        ...data,
        farmer_ids: farmerIds,
        unit,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
}
